import React,{useState} from 'react';
import './RegForm.scss';
import Select from 'react-select';
import TextInput from "./TextInput";
import Utils from "../../../utils/utils";
import Admin from "../../../utils/Admin";
import PasswordInput from "./PasswordInput";
import Modal from "../../ui/main/Modal";
import RecoveryForm from "./RecoveryForm";


const LOCAL_API_KEY_NAME = 'analytics_api_key';

const LoginForm = ({authHandler}) => {

    const [formState,setFormState] = useState({
        data : {},
        disabled : false,
        valid : true,
        terms : true,
        closed : false,
        hidden : false,
        inputs : Utils.toObjectByKey([
            {
                type : 'text',
                name : 'email',
                cssClass : '',
                placeholder : 'Email*',
                error : '',
                valid : true,
                value : ''
            },
            {
                type : 'password',
                name : 'password',
                cssClass : '',
                placeholder : 'Password*',
                error : '',
                valid : true,
                value : ''
            }
        ],"name")
    });

    const [recoveryStep,setRecovery] = useState(0);
    const params = Utils.getUrlParameter();

    const baseErrors = {
        email : 'Invalid email',
        password : 'Password must contain: 8 characters or more with at least one uppercase, lowercase, numeric digit and one of the characters listed: @$!%*?&#'
    };

    const fieldValidator = (name,val) => Utils.case(name,{
        'email' : () => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val.toLowerCase()),
        'password' : () => (val.length >= 8) && /[A-Z]/.test(val) && /[a-z]/.test(val) && /[@$!%*?&#]/.test(val) && /[0-9]/.test(val),
        'default' : () => true
    });

    const handleTextChange = (e,name) => {
        let val = String(e.target.value).trim();
        formState.data[name] = val;
        formState.inputs[name].value = val;
        setFormState({...formState});
    };

    const handleSelectChange = ({value},i,name) => {
        formState.data[name] = value;
        formState.inputs[name].value = value;
        formState.inputs[name].valid = true;
        setFormState({...formState});
    };

    const handleCheckboxChange = ({target}) => {
        formState.terms = target.checked;
        setFormState({...formState});
    };

    const validateForm = () => {
        formState.valid = true;
        Object.keys(formState.inputs).forEach(key => {
            let val = formState.data[key],
                inp = formState.inputs[key],
                error = '';

            if(!val || !fieldValidator(key,val)){
                formState.valid = false;
                inp.valid = false;
                error = baseErrors[key] ? baseErrors[key] : '';
            }else{
                error = '';
                inp.valid = true;
            }
            inp.error = error;
        });

        if(!formState.terms){
            formState.valid = false;
        }
    };

    const resetForm =  () => {
        Object.values(formState.inputs).forEach(e => e.value = '');
    };

    const handleError = error => {
        alert(`SERVER ERROR: ${error}`)
    };

    const submitForm = async () => {
        validateForm();
        if(formState.valid){
            let response,status;
            formState.disabled = true;
            setFormState({...formState});
            response = await  Admin.signIn(formState.data);
            formState.disabled = false;
            setFormState({...formState});
            console.log(response)
            if(response.status === 2) handleError(response.error);
            if(response.status === 1){
                resetForm();
                setFormState({...formState});
                Utils.toLS(LOCAL_API_KEY_NAME,response.user.api_key);
                authHandler(true);
            }
        }else{
            setFormState({...formState});
        }

    };

    const getComponent = key => ({
        'text' : (config,index) => TextInput({key : index,config,handleChange : handleTextChange}),
        'password' : (config,index) => PasswordInput({key : index,config,handleChange : handleTextChange}),
        'select' : (config,index) => {
            return <div className={`form-row ${config.valid ? '' : 'invalid'}`} key={index}>
                <Select
                    value={config.value ? config.options[config.value] : ''}
                    onChange={(v) => handleSelectChange(v,index,config.name)}
                    options={config.options}
                    placeholder={config.placeholder}
                    isSearchable={false}
                    classNamePrefix={config.cssClass}
                />
            </div>
        }

    })[key];

    return <>
        <div id="affise-reg-popup" className={"affise-modal-layout close-affise-reg-popup" + (recoveryStep === 0 ? "" : " __hidden")}>
            <div className={`form-section ${formState.disabled ? 'disabled' : ''} ${formState.closed ? 'closed' : ''} ${formState.hidden ? 'hidden' : ''}`}>
                <div className="form-header">Authorization</div>
                <div className="form-container">
                    <form id="reg_form">
                        {
                            Object.values(formState.inputs).map((el,i) => getComponent(el.type)(el,i))
                        }
                    </form>
                </div>
                <div className="terms-container">
                    <div className="term-row">
                        <input className={`form-checkbox ${formState.terms === false ? 'invalid' : ''}`} type="checkbox" id="terms" onChange={handleCheckboxChange} checked={formState.terms}/><label className="term-label" htmlFor="terms" />
                        <div className="term-text">I agree with <a href="https://my.affiliates.cyber.bet/v2/terms" target="_blank">Terms And Conditions</a></div>
                    </div>
                </div>
                <div className="buttons-container">
                    <div className="button button_create" id="create_button" onClick={submitForm}>Sign In</div>
                </div>
                <div className="recovery-link" onClick={() => setRecovery(1)}><span>Forgot your password?</span></div>
                <div className="login-link"><a href="register">Create Account</a></div>
            </div>
        </div>
        <RecoveryForm visible={recoveryStep === 1} handler={() => setRecovery(2)}/>
        {
            recoveryStep === 2 && <div className={"Congrats-request-modal"}><Modal onClose={() => setRecovery(0)} title={'Congratulation!'} body={'Email with recovering link was sent.'}/></div>
        }
    </>
};

export default LoginForm;