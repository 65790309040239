import React,{useContext} from 'react';
import {Redirect} from "react-router-dom";
import {authContext} from "../utils/Affise";
import LoginForm from "../components/auth/form/LoginForm";

const Login = (props) => {
    const {auth} = useContext(authContext);
    return auth ? <Redirect to="/ui/dashboard" /> : <LoginForm authHandler={props.authHandler}/>;
};

export default Login;