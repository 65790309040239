class Utils {

    static empty(mixed){
        return !Object.values(mixed).length;
    }

    static getUrlParameter(){
        const search = window.location.search.substring(1);
        const params = search.split('&');
        let result = {};

        params.forEach((pair) => {
            const res = pair.split('=');
            result[res[0]] = res[1] ? decodeURIComponent(res[1]) : undefined;
        });
        return result;
    }

    static sortByKey(objArray,sortKey,reverse){
        objArray = Object.values(objArray);
        objArray.sort(function (a,b) {
            return (a[sortKey]<b[sortKey]) ?  -1 : 1;
        });
        return !reverse ? objArray : objArray.reverse();
    };

    static getKeysSet(objArray,key,predicate){
        let result = [];
        Object.values(objArray).forEach(e => (e[key] && (predicate ? predicate(e) : true) ? result = result.concat(e[key]) : 1))
        return [...(new Set(result))]
    }

    static getKeysDiff(obj1,obj2,keys = null){let noDiff = true, result = {};for(let key in obj1){if(!keys || keys.includes(key)){if(obj1[key] !== obj2[key]){result[key] = obj1[key];noDiff = false;}}}return noDiff ? null : result;}

    static replaceTPL(obj,tpl){
        return Object.entries(obj).reduce((str,[key,val]) => {str = str.replace(RegExp(`{{${key}}}`, 'g'),val);return str;},tpl);
    }

    static replaceTPL2Up(obj,tpl){
        return Object.entries(obj).reduce((str,[key,val]) => {str = str.replace(RegExp(`{{{${key}}}}`, 'g'),String(val).toUpperCase()).replace(RegExp(`{{${key}}}`, 'g'),val);return str;},tpl);
    }

    static rnd(mixed) {
        return mixed instanceof Array ? mixed[Utils.rnd(mixed.length) - 1] : Math.ceil(Math.random() * mixed);
    }

    static arrCut(arr,i){
        arr = [].concat(arr.slice(0,i),arr.slice(i+1));
        return arr ;
    }

    static getUniqRndCount(mixed,count){
        return mixed instanceof Array ? new Array(count).fill(1).map(() => {
            let n = Utils.rnd(mixed.length) - 1,
                el = mixed[n];
            mixed = Utils.arrCut(mixed,n);
            return el;
        }) : Utils.getUniqRndCount(Object.keys(new Array(mixed + 1).fill(1)).slice(1).map(e => +e),count);
    }

    static arrShuffle(arr){
        return Utils.getUniqRndCount(arr,arr.length)
    }

    static case(key, cases){
        return cases[key] ? cases[key]() : cases.default ? cases.default() : undefined;
    }

    static async waterfall(arr,handler,packageSize,result){
        let pack = packageSize ? arr.slice(0,packageSize) : arr.shift();
        arr = packageSize ? arr.slice(packageSize): arr;
        result = result || [];

        if(packageSize ? pack[0] : pack){
            result = result.concat(await handler(pack));
            result = await Utils.waterfall(arr,handler,packageSize,result);
        }
        return result;
    };

    static async whileDo(condition,handler){
        if(!condition()) return;
        await handler();
        await Utils.whileDo(condition,handler);
    }

    static toObjectByKey(arr, key = 'id'){
        return arr.reduce((o,e) => {o[e[key]] = e; return o},{});
    }

    static getIP(req){
        return (req.headers['x-forwarded-for'] || '').split(',').pop() ||
            req.connection.remoteAddress ||
            req.socket.remoteAddress ||
            req.connection.socket.remoteAddress
    }

    static arrayFusion(keysArr,dataArr,fkCallback){
        return keysArr.map((el,i) => ({
            FK : fkCallback ? fkCallback(el) : el,
            data : dataArr[i]
        }))
    }

    static arrayUnion(arr1,arr2){
        return [...(new Set([...arr1,...arr2]))]
    }

    static arrIntersection(arr1,arr2){
        return arr1.filter(e => arr2.includes(e));
    }

    static sleep(time){
        return new Promise(resolve => {
            setTimeout(() => resolve(),time)
        })
    }

    static ipZerofill(ip){
        return ip.split('.').map((d,i) => String(i ? d.padStart(3,0): d)).join('')*1;

    }

    static getDate(ISO,splitter = "."){
        return ( d => `${String(d.getDate()).padStart(2,'0')}${splitter}${String(d.getMonth() + 1).padStart(2,'0')}${splitter}${d.getFullYear()}`)(new Date(ISO))
    }
    static getTime(ISO){
        return ( d => `${String(d.getHours()).padStart(2,'0')}:${String(d.getMinutes()).padStart(2,'0')}`)(new Date(ISO))
    }

    static toLS(key, data){
        return localStorage.setItem(key, JSON.stringify(data));
    };

    static fromLS(key, defaultValue){
        return localStorage.getItem(key) !== null ? JSON.parse(localStorage.getItem(key)) : defaultValue;
    };

    static toSS(key, data){
        return sessionStorage.setItem(key, JSON.stringify(data));
    };

    static fromSS(key, defaultValue){
        return sessionStorage.getItem(key) !== null ? JSON.parse(sessionStorage.getItem(key)) : defaultValue;
    };

    static qsEncode(data){
        return Object.entries(data).map(([k,v]) => `${k}=${encodeURIComponent(v)}`).join('&')
    }

    static arrSum(arr){
        return arr.reduce((a,b) => a+b)
    }

    static getAffiseDate(ts){
        return ((d => `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2,'0')}-${String(d.getDate()).padStart(2,'0')}`)(new Date(ts)))
    }

    static async appendScripts(scripts){
        await Utils.waterfall(scripts, async config => new Promise(resolve => {
            let script = document.createElement('script');
            script.onload = () => {
                resolve();
            };
            script.type = 'text/javascript';
            script.src = config.src;
            script.id = config.id || '';
            document.head.append(script);
        }))
    }

    static objectToFormData(dataObject){
        let formData = new FormData();
        Object.entries(dataObject).forEach(el => formData.append(el[0],el[1]));
        return formData;
    }

    static composition(list,value){
        return list.reduce((result,f) => f(result),value);
    }
    static getKeysSum(mixed){
        return Object.values(mixed).reduce((result,obj) => {
            for(let key in obj){
                let val = +obj[key];
                if(isNaN(val))continue;
                result[key] ? result[key] += val : result[key] = val;
            }
            return result;
        },{})
    }

};

export default Utils;

export const getScreenUrl = url => `https://cdnstatic.cyber.bet/img/screenshots/landings/${btoa(url).replace('/','')}.jpeg`;

export const mapComponents = (mixed,item) => Object.values(mixed).map((e,key) => item({...e,key}))