import React,{useContext} from 'react';
import {Redirect} from "react-router-dom";

import {authContext, userDataContext} from "./../utils/Affise";
import Header from "../components/ui/main/Header";
import Aside from "../components/ui/main/Aside";
import Body from "../components/ui/main/Body";
import Preloader from "../components/ui/main/Preloader";

//const Zendesk = () => <script src={}/>

const Home = (props) => {
    const {auth} = useContext(authContext);
    const {user} = useContext(userDataContext);

    return auth
        ?
        <div  className={ !user ? "data-loading" : ""}>
            {
                user && <div className={"app-layout"}>
                    <Header/>
                    <Body section={props.match.params.section} subsections={{...props.match.params}}/>
                    <Aside section={props.match.params.section}/>
                </div>
            }
            <Preloader/>
        </div>

        : <Redirect to="/login" />;
};
export default Home;