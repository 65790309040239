import React,{useState} from 'react';
import './RegForm.scss';
import Select from 'react-select';
import TextInput from "./TextInput";
import Utils from "../../../utils/utils";
import {AFFISE} from "../../../utils/Affise";
import PasswordInput from "./PasswordInput";
import Modal from "../../ui/main/Modal";


const RecoveryForm = ({visible,handler}) => {

    const [formState,setFormState] = useState({
        data : {},
        disabled : false,
        valid : true,
        terms : true,
        closed : false,
        hidden : false,
        inputs : Utils.toObjectByKey([
            {
                type : 'text',
                name : 'email',
                cssClass : '',
                placeholder : 'Email*',
                error : '',
                valid : true,
                value : ''
            }
        ],"name")
    });

    const baseErrors = {
        email : 'Invalid email',
        password : 'Password must contain: 8 characters or more with at least one uppercase, lowercase, numeric digit and one of the characters listed: @$!%*?&#'
    };

    const fieldValidator = (name,val) => Utils.case(name,{
        'email' : () => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val.toLowerCase()),
        'password' : () => (val.length >= 8) && /[A-Z]/.test(val) && /[a-z]/.test(val) && /[@$!%*?&#]/.test(val) && /[0-9]/.test(val),
        'default' : () => true
    });

    const handleTextChange = (e,name) => {
        let val = String(e.target.value).trim();
        formState.data[name] = val;
        formState.inputs[name].value = val;
        setFormState({...formState});
    };

    const validateForm = () => {
        formState.valid = true;
        Object.keys(formState.inputs).forEach(key => {
            let val = formState.data[key],
                inp = formState.inputs[key],
                error = '';

            if(!val || !fieldValidator(key,val)){
                formState.valid = false;
                inp.valid = false;
                error = baseErrors[key] ? baseErrors[key] : '';
            }else{
                error = '';
                inp.valid = true;
            }
            inp.error = error;
        });

        if(!formState.terms){
            formState.valid = false;
        }
    };

    const resetForm =  () => {
        Object.values(formState.inputs).forEach(e => e.value = '');
    };

    const handleError = error => {
        alert(error);
    };

    const submitForm = async () => {
        validateForm();
        if(formState.valid){
            let response,status;
            formState.disabled = true;
            setFormState({...formState});
            console.log(formState.data);
            response = await  AFFISE.passRecoveryRequest(formState.data);
            formState.disabled = false;
            if(response.status === 2){
                setFormState({...formState});
                handleError(response.error);
            }
            if(response.status === 1){

                resetForm();
                setFormState({...formState});

                handler();
            }
        }else{
            setFormState({...formState});
        }

    };

    const getComponent = key => ({
        'text' : (config,index) => TextInput({key : index,config,handleChange : handleTextChange})

    })[key];

    return <div className={"affise-modal-layout close-affise-reg-popup" + (visible ? "" : " __hidden") }>
        <div className={`form-section ${formState.disabled ? 'disabled' : ''} ${formState.closed ? 'closed' : ''} ${formState.hidden ? 'hidden' : ''}`}>
            <div className="form-header">Password recovery</div>
            <div className="form-container">
                <div className={"form-descr"}>Enter email to get link for password recovering</div>
                <form id="reg_form">
                    {
                        Object.values(formState.inputs).map((el,i) => getComponent(el.type)(el,i))
                    }
                </form>
            </div>
            <div className="buttons-container">
                <div className="button button_create" id="create_button" onClick={submitForm}>Recover the password</div>
            </div>
        </div>
    </div>
};

export default RecoveryForm;