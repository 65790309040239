import React, {useContext, useState} from 'react';
import './css/FiltersForm.scss';
import Select from 'react-select';
import Utils from "../../../../utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import iso2 from "../../../../data/iso2";
import {SliceContext} from "./contexts";
import DatePresets from "./DatePresets";

const FiltersForm = (props) => {

    let {currentFilters,filtersUpdater,offers} = props;

    const {subsection} = useContext(SliceContext);

    let offerOptions = offers.map(e => ({value : e.id, label : `ID${e.id}: ${e.title}`}));

    let history = useHistory();

    const handleDateChange = (date,key) => {
        formState.inputs[key].value = date.getTime();
        currentFilters[`filter[${key}]`] = date.getTime();
        setFormState({...formState});
        // let val = String(e.target.value).trim();
        // formState.data[name] = val;
        // formState.inputs[name].value = val;
        // setFormState({...formState});
    };

    const [preset, setPreset] = useState(2);

    const [formState,setFormState] = useState({
        data : {},
        disabled : false,
        inputs : Utils.toObjectByKey([
            {
                type : 'label',
                name : 'label_1',
                value : 'Date:'
            },
            {
                type : 'date',
                name : 'date_from',
                cssClass : '',
                placeholder : '',
                value : currentFilters['filter[date_from]'],
                label : 'From:'
            },
            {
                type : 'date',
                name : 'date_to',
                cssClass : '',
                placeholder : '',
                value : currentFilters['filter[date_to]'],
                label : 'To:'
            },
            {
                type : 'label',
                name : 'label_2',
                value : 'Add offers:'
            },
            {
                type : 'multi_select',
                name : 'offer',
                cssClass : 'FiltersForm_multi_select',
                placeholder : 'Select offers...',
                value : currentFilters.offer ? offerOptions.filter(({value}) => currentFilters.offer.includes(value)) : [],
                options : offerOptions
            },
            {
                type : 'label',
                name : 'label_3',
                value : 'Add countries:'
            },
            {
                type : 'multi_select',
                name : 'country',
                cssClass : 'FiltersForm_multi_select',
                placeholder : 'Select countries...',
                value : currentFilters.country ? iso2.filter(({value}) => currentFilters.country.includes(value)) : [],
                options : iso2
            }
        ],"name")
    });

    const applyFilters = () => {
        let params = Object.values(formState.inputs).filter(e => !e.name.includes('label_')).reduce((o,e) => {o[e.name] = e.value;return o},{});
        //console.log('params',params);
        params.offer = params.offer.map(e => e.value);
        params.country = params.country.map(e => e.value);
        params.date_from = Utils.getAffiseDate(params.date_from);
        params.date_to = Utils.getAffiseDate(params.date_to);
        if(params.offer[0]){
            params.offer = params.offer.join(',')
        }else{
            delete(params.offer);
        }
        if(params.country[0]){
            params.country = params.country.join(',')
        }else{
            delete(params.country);
        }
        filtersUpdater({...currentFilters});
        history.push(`/ui/stats/${subsection}?` + Utils.qsEncode(params));
    };


    const handleMultiSelectChange = (value,name) => {
        formState.inputs[name].value = value;
        currentFilters[name] = value.map(e => e.value);
        setFormState({...formState});
    };

    const clearFilters = () => {
        setPreset(2);
        handleDateChange((new Date(Date.now() - 7*24*3600*1000)),'date_from');
        handleDateChange((new Date()),'date_to');
        handleMultiSelectChange([],'offer');
        handleMultiSelectChange([],'country');
    };

    const getComponent = key => ({
        'label' : (config,index) => {
            return <div className={"form-label"} key={index}>{config.value}</div>

        },
        'component' : (config,index) => <div className={"form-row"} key={index}>
            {config.value()}
        </div>,
        'date' : (config,index) => {
            return <div className={"DatePicker"} key={index}>
                <div className="DatePiker-label">{config.label}</div>
                <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={config.value}
                    onChange={v => {
                        handleDateChange(v,config.name);
                    }} //only when value has changed
                />
            </div>


        },
        'multi_select' : (config,index) => {
            return <div className={`form-row ${config.valid ? '' : 'invalid'}`} key={index}>
                <Select
                    key={index}
                    value={config.value}
                    onChange={(v) => handleMultiSelectChange(v,config.name)}
                    isMulti={true}
                    options={config.options}
                    placeholder={config.placeholder}
                    isSearchable={false}
                    classNamePrefix={config.cssClass}
                />
            </div>
        }

    })[key];
    //console.log(formState)
    return <div className={'FiltersForm-wrapper'}>
            <div className={"ui-body-Offers-FiltersForm " + (preset !== 5 ? "hidePresets" : "")}>
                <DatePresets dateInputHandler={handleDateChange} preset={preset} setPreset={setPreset}/>
                {
                    Object.values(formState.inputs).map((el,i) => getComponent(el.type)(el,i))
                }
            <div className={"applyButton"} onClick={applyFilters}>Apply</div>
            <div className={"clearButton"} onClick={clearFilters}>Clear</div>
        </div>
    </div>
};

export default FiltersForm;