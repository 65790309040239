import React,{useContext} from 'react';
import './css/Header.scss';
import {userDataContext, authContext, prepareSum} from "../../../utils/Affise";
import {Link} from "react-router-dom";
import SmallIcon from "./SmallIcon";

const LOCAL_API_KEY_NAME = 'analytics_api_key';

const Header = (props) => {
    const {user} = useContext(userDataContext);
    const {setAuth,clearUserData} = useContext(authContext);
    const logOut = () => {
        localStorage.removeItem(LOCAL_API_KEY_NAME);
        setAuth(false);
        clearUserData();
    };
    //console.log(user);
    return  <div className={"ui-main-Header"}>
                <div  className={"logo-text"}>Analytics</div>
                {
                    user &&
                        <div className={"flex-mid"}>
                            <div className={"info-wrapper"}>
                                <div className={"id"}>{user.email}</div>
                            </div>
                            <div className={"info-buttons"}>
                                {/*<Link to={"/ui/profile"} className={"button"}><SmallIcon icon={'profile'}/></Link>*/}
                                <div className={"button"} onClick={logOut}><SmallIcon icon={'logout'}/></div>
                            </div>
                        </div>

                }
            </div>
};

export default Header;