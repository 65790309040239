import React, {useState} from 'react';

const Toggler = ({id,title,handler,showHold}) => {

    return  <div className={"toggler-wrapper"}>
        <input type={"checkbox"} id={id} checked={showHold} onChange={(e) => handler(e.target.checked)}/>
        <label className={"toggler-title"} htmlFor={id}>{title}</label>
    </div>
};

export default Toggler;