import React from 'react';
import Utils from "../../../../../utils/utils";
import {mergeGoalsAndHitsForSliceTable} from "../../../../../utils/Affise";
import TableRow from "./TableRow";
import TableHead from "./TableHead";
import TableView from "./TableView";

const decorator = ({goals,hits}) => {

    goals = goals.reduce((o,e) => {
        let key = e.slice.offer.id;

        if(!o[key])o[key] = {
            slice : key,
            sliceData : e.slice.offer.title,
            goals : {}
        };
        o[key].goals[e.slice.goal_value] = e.actions;
        return o;
    },{});

    hits = hits.reduce((o,e) => {
        let hosts = +e.traffic.uniq,
            hits = +e.traffic.raw,
            key = e.slice.offer.id,
            views = e.views;
        if(!hosts && !hits) return o;
        if(!o[key])o[key] = {
            hosts,
            hits,
            slice : key,
            goals : goals[key] ? goals[key].goals : null,
            sliceData : `ID${key}: ${e.slice.offer.title}`,
            views
        };
        return o;
    },{});

    return mergeGoalsAndHitsForSliceTable(goals,hits,);
};

const OfferTable = ({data,sortHandler,showHold}) => <TableView rows={decorator(data)} sortHandler={sortHandler}  sliceName={'Offer'} showHold={showHold}/>;

export default OfferTable;