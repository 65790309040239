import React, {useContext} from 'react';
import Utils from "../../../../../utils/utils";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import {headScrollContext, SliceContext} from "../contexts";

const percentage = (a,b) => `${Math.round((a/b)*10000)/100}%`;

const TableView = ({rows,sortHandler,sliceName,hasSliceHover,showHold}) => {
    rows =  Utils.sortByKey(rows,(sortHandler.sortKey || 'slice'),sortHandler.direction === 'forward');
    let totalRow = Utils.getKeysSum(rows);
    totalRow.sliceData = 'Total';

    //const {headScroll} = useContext(headScrollContext);
    totalRow.ctr = totalRow.imps &&  totalRow.hits ? percentage(totalRow.hits,totalRow.imps) : 0;
    totalRow.reg_click = totalRow.regs &&  totalRow.hits ? percentage(totalRow.regs,totalRow.hits) : 0;
    totalRow.fd_click = totalRow.FTD_total &&  totalRow.hits ? percentage(totalRow.FTD_total,totalRow.hits) : 0;
    totalRow.fd_reg = totalRow.FTD_total &&  totalRow.regs ? percentage(totalRow.FTD_total,totalRow.regs) : 0;

    return <div className={"table-frame"}>
        <div className={"table-inner-frame"}>
        <table className={"stats-table " + (hasSliceHover ? "slice-hover" : "")}>
            <TableHead sliceName={sliceName} sortHandler={sortHandler}/>
            <tbody>
            {
                rows.map((row,i) => <TableRow row={row} key={`${Utils.rnd(10000)}_${i}`} showHold={showHold}/>)
            }
            <TableRow row={totalRow} showHold={showHold}/>
            </tbody>

        </table>
        <div className={"table-head-wrapper"}>

            <table className={"stats-table_invisible stats-table " + (hasSliceHover ? "slice-hover" : "")}>
                <TableHead sliceName={sliceName} sortHandler={sortHandler}/>
                <tbody>
                {
                    rows.map((row,i) => <TableRow row={row} key={`${Utils.rnd(10000)}_${i}`} showHold={showHold}/>)
                }
                <TableRow row={totalRow} showHold={showHold}/>
                </tbody>

            </table>
        </div>
        </div>
        </div>
        ;
};

export default TableView;