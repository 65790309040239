import './global.scss';
import './App.scss';
import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Register from "./routes/Register";
import Login from "./routes/Login";
import Home from "./routes/Home";
import {AFFISE, authContext, userDataContext} from "./utils/Affise";
import Utils from "./utils/utils";
import Admin from "./utils/Admin";
import Zendesk from "./utils/Zendesk";
import Confirm from "./routes/Confirm";
import Recovery from "./routes/Recovery";

const LOCAL_API_KEY_NAME = 'analytics_api_key';

const App = () => {
    const [auth, setAuth] = useState(Admin.checkAuth());
    const [user, setUser] = useState(null);


    useEffect(() => {
        if (auth && !user) {
            Admin.getUserData().then(data => {
                if(data){
                    setUser(data);
                }else{
                    alert('Аккаунт не активен');
                    localStorage.removeItem(LOCAL_API_KEY_NAME);
                    setAuth(false);
                }
            });
        }
    }, [auth, user]);

    return (
        <authContext.Provider
            value={{
                auth,
                setAuth,
                clearUserData : () => setUser(null)
            }}
        >
            <userDataContext.Provider value={{
                user,
                setUser
            }}>
                <Router>
                    <Switch>
                        <Route exact path="/:section/:subsection?/:sub1?/:sub2?" component={props => Home({...props})}/>
                        <Route path="/login" component={() => Login({authHandler: setAuth})}/>
                        <Route path="/register" component={Register}/>
                        {/*<Route path="/confirm" component={Confirm}/>*/}
                        {/*<Route path="/recovery" component={Recovery}/>*/}
                        <Route>
                            <Redirect to="/login"/>
                        </Route>
                    </Switch>
                </Router>
            </userDataContext.Provider>
        </authContext.Provider>
    );
}

export default App;
