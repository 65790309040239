import React, {useState} from 'react';
import './css/Aside.scss';
import {Link} from 'react-router-dom';
import SmallIcon from "./SmallIcon";

const AsideToggleButton = ({active,handler}) => {
    return <>
        <div className={"aside-burger " + (active ? 'aside-burger__active' : '')} onClick={() => handler(!active)}>

        </div>
    </>
};

const links = [
    {
        key : "dashboard",
        title : "Dashboard",
        link : '/ui/dashboard'
    }
];
const Aside = (props) => {
    let {section} = props;
    const [active,setActive] = useState(false);
    return  <>
        <AsideToggleButton active={active} handler={setActive}/>
        <div className={"ui-main-Aside " + (active ? 'ui-main-Aside__active' : '')}>
                <div className={"nav"}>
                    {
                        links.map(({title,link,key}) => <Link to={link} key={key} onClick={() => setActive(false)} className={`nav-link ${key === section ? 'nav-link__active' : ''}`}><SmallIcon icon={key}/><div>{title}</div></Link>)
                    }
                </div>
            </div>
        </>

};

export default Aside;