import React from 'react';
import Utils from "../../../../../utils/utils";
import {mergeGoalsAndHitsForSliceTable} from "../../../../../utils/Affise";
import TableView from "./TableView";

const decorator = ({goals,hits}) => {

    goals = goals.map(e => {
        let row = {
            key :  `${e.slice.month}/${e.slice.day}/${e.slice.year}`,
            ...e
        };
        row.time = (new Date(row.key)).getTime();
        return row;
    }).reduce((o,e) => {
        if(!o[e.key])o[e.key] = {
            key : e.key,
            sliceData : Utils.getAffiseDate(e.time),
            slice : e.time,
            goals : {}
        };
        o[e.key].goals[e.slice.goal] = e.actions;
        return o;
    },{});

    hits = hits.reduce((o,e) => {

        let hosts = +e.traffic.uniq,
            hits = +e.traffic.raw,
            key = `${e.slice.month}/${e.slice.day}/${e.slice.year}`,
            time = (new Date(key)).getTime(),
            views = e.views;
        //if(!hosts && !hits) return o;
        if(!o[key])o[key] = {
            hosts,
            hits,
            slice : time,
            goals : goals[key] ? goals[key].goals : null,
            sliceData : Utils.getAffiseDate(time),
            views
        };
        return o;
    },{});

    return mergeGoalsAndHitsForSliceTable(goals,hits);
};

const DailyTable = ({data,sortHandler,showHold}) => <TableView rows={decorator(data)} sortHandler={sortHandler} sliceName={'Date'} showHold={showHold}/>;

export default DailyTable;