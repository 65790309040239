import React,{useState} from 'react';

const PasswordInput = (props) => {
    let {name,cssClass,placeholder,error,valid,value} = props.config;
    const [closed,setState] = useState(true);
    return  <div className="form-row" key={props.key}>
            <input name={name} className={`form-input ${cssClass} ${valid ? '' : 'invalid'}`} value={value} type={closed ? 'password' : 'text' } placeholder={placeholder} onChange={e => props.handleChange(e,name)}/>
                <div className="error_message" title={error}>{error}</div>
                {closed ? <div className={"unlock"} onClick={() => setState(!closed)}/> : <div className={"lock"} onClick={() => setState(!closed)}/>}
            </div>
};

export default PasswordInput;