import React, {useState} from 'react';
import DailyTable from "./DailyTable";
import OffersTable from "./OffersTable";
import CountryTable from "./CountryTable";
import DeviceTable from "./DeviceTable";
import OsTable from "./OsTable";
import SubTable from "./SubTable";
import {useTableSort} from "../useTableSort";
import LandingTable from "./LandingTable";
import Toggler from "../../../utils/Toggler";

const TableSwitcher = ({subsection,data,sliceTitle,showHold,setHold}) => {
    const sortHandler = useTableSort();
    return  <>
        <Toggler title={'Show conversions on hold'} id={'hold'} showHold={showHold} handler={e => setHold(e)} />
        {
            subsection === 'daily' && <DailyTable sortHandler={sortHandler} data={data} showHold={showHold}/>
        }
        {
            subsection === 'offers' && <OffersTable  sortHandler={sortHandler}  data={data} showHold={showHold}/>
        }
        {
            subsection === 'landings' && <LandingTable  sortHandler={sortHandler}  data={data} showHold={showHold}/>
        }
        {
            subsection === 'countries' && <CountryTable  sortHandler={sortHandler}  data={data} showHold={showHold}/>
        }
        {
            subsection === 'devices' && <DeviceTable  sortHandler={sortHandler}  data={data} showHold={showHold}/>
        }
        {
            subsection === 'os' && <OsTable  sortHandler={sortHandler}  data={data} showHold={showHold}/>
        }
        {
            subsection === 'creatives' && <SubTable  sortHandler={sortHandler}  data={data} showHold={showHold} sub={'sub5'} sliceTitle={sliceTitle}/>
        }
        {
            (subsection.slice(0,3) === 'sub') && <SubTable  sortHandler={sortHandler}  data={data} showHold={showHold} sub={subsection} sliceTitle={sliceTitle}/>
        }
    </>
};

export default TableSwitcher;