import React from 'react';
import Utils from "../../../../../utils/utils";
import {mergeGoalsAndHitsForSliceTable} from "../../../../../utils/Affise";
import TableRow from "./TableRow";
import TableHead from "./TableHead";
import iso2 from "../../../../../data/iso2";
import TableView from "./TableView";

const countryNames = Utils.toObjectByKey(iso2,'value');
countryNames['_N/A'] = {label : 'N/A'};
const decorator = ({goals,hits}) => {

    goals = goals.reduce((o,e) => {
        let key = e.slice.country || '_N/A';
        if(!o[key])o[key] = {
            slice : key,
            sliceData : countryNames[key].label,
            goals : {}
        };
        o[key].goals[e.slice.goal] = e.actions;
        return o;
    },{});

    hits = hits.reduce((o,e) => {
        let hosts = +e.traffic.uniq,
            hits = +e.traffic.raw,
            key = e.slice.country || '_N/A',
            views = e.views;
        if(!hosts && !hits) return o;
        if(!o[key])o[key] = {
            hosts,
            hits,
            slice : key,
            goals : goals[key] ? goals[key].goals : null,
            sliceData : countryNames[key].label,
            views
        };
        return o;
    },{});

    return mergeGoalsAndHitsForSliceTable(goals,hits);
    //return ({offers,hits});
};

const CountryTable = ({data,sortHandler,showHold}) => <TableView rows={decorator(data)} sortHandler={sortHandler}  sliceName={'Country'} showHold={showHold}/>;

export default CountryTable;