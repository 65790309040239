import React from 'react';
import {prepareSum} from "../../../../../utils/Affise";

const WithHold = (v,h) => <div className={"with-hold-TD"}>
    <div className={"confirmed-val"}>{v}</div>
    <div className={"hold-val"}>{h}</div>
</div>;

const TD = ({val,showHold,holdVal}) => <td className={((+val === 0 || val === '€0.00'|| val === '0%') && (+holdVal === 0 || holdVal === '€0.00'|| holdVal === '0%' || holdVal === undefined) ? "zeroData" : '') }>
    {
        showHold ?
            <div className={"with-hold-TD"}>
                <div className={"confirmed-val"}>{val}</div>
                <div className={"hold-val"}>{holdVal}</div>
            </div>
            :
            val
    }
</td>;
const TableRow = ({row,showHold}) => {
    return <tr>
        <TD val={row.sliceData}/>
        <TD val={row.imps || 0}/>
        <TD val={row.hits || 0}/>
        <TD val={row.regs || 0}/>
        <TD val={row.FTD || 0} holdVal={row.FTD_hold || 0} showHold={showHold}/>
        <TD val={row.total_deps || 0} holdVal={row.total_deps_hold || 0} showHold={showHold}/>
        <TD val={(row.ctr || 0) + '%'}/>
        <TD val={(row.reg_click || 0) + '%'}/>
        <TD val={(row.fd_click || 0) + '%'}/>
        <TD val={(row.fd_reg || 0) + '%'}/>
        <TD val={prepareSum(row.FTD_charge || 0)} holdVal={prepareSum(row.FTD_charge_hold || 0)} showHold={showHold}/>
        <TD val={prepareSum(row.total_deps_charge || 0)} holdVal={prepareSum(row.total_deps_charge_hold || 0)} showHold={showHold}/>
        <TD val={prepareSum(row.betting_GGR || 0)} holdVal={prepareSum(row.betting_GGR_hold || 0)} showHold={showHold}/>
        <TD val={prepareSum(row.betting_NGR || 0)} holdVal={prepareSum(row.betting_NGR_hold || 0)} showHold={showHold}/>
        <TD val={prepareSum(row.casino_GGR || 0)} holdVal={prepareSum(row.casino_GGR_hold || 0)} showHold={showHold}/>
        <TD val={prepareSum(row.casino_NGR || 0)} holdVal={prepareSum(row.casino_NGR_hold || 0)} showHold={showHold}/>
        <TD val={prepareSum(row.CPA || 0)} holdVal={prepareSum(row.CPA_hold || 0)} showHold={showHold}/>
        <TD val={prepareSum(row.betting_revshare || 0)} holdVal={prepareSum(row.betting_revshare_hold || 0)} showHold={showHold}/>
        <TD val={prepareSum(row.casino_revshare || 0)} holdVal={prepareSum(row.casino_revshare_hold || 0)} showHold={showHold}/>
        <TD val={prepareSum(row.total || 0)} holdVal={prepareSum(row.total_hold || 0)} showHold={showHold}/>
    </tr>
};

export default TableRow;