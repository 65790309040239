import Utils from "./utils";

const LOCAL_API_KEY_NAME = 'analytics_api_key';
const LOCAL_DEV = 0;
const BACKEND_URL =  LOCAL_DEV ? 'http://localhost:3001/admin' : 'https://strictapps.ru/admin';
class Admin {

    constructor() {
        this.checkApiKey();
        this.apiURL = 'https://api-gaming-partners.affise.com';
    }

    checkApiKey(){
        let params = Utils.getUrlParameter();
        if(params.k && params.k.length === 32) Utils.toLS(LOCAL_API_KEY_NAME,params.k);
        this.apikey = Utils.fromLS(LOCAL_API_KEY_NAME);
    }

    checkAuth(){
        return  !!this.apikey
    }

    async signUp(data){

        return await fetch(`${BACKEND_URL}/api/user/sign_up`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data),
        }).then(res => res.json());
    };

    async signIn(data){

        return await fetch(`${BACKEND_URL}/api/user/sign_in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data),
        }).then(res => res.json());
    };

    async getUserData(){
        this.checkApiKey();
        let result = await fetch(`${BACKEND_URL}/api/user/data?api_key=${this.apikey}`).then(res => res.json());
        return result.status === 1 ? result.user : null;
    };

}

export default Admin =  new Admin();