import React, {useContext, useMemo} from 'react';
import Utils from "../../../../../utils/utils";
import {mergeGoalsAndHitsForSliceTable, userDataContext} from "../../../../../utils/Affise";
import TableRow from "./TableRow";
import TableHead from "./TableHead";
import TableView from "./TableView";

const decorator = ({goals,hits}) => {
        //console.log({goals,hits});
    goals = goals.reduce((o,e) => {
        let key = e.slice.landing;

        if(!o[key])o[key] = {
            slice : key,
            sliceData : key,
            goals : {}
        };
        o[key].goals[e.slice.goal] = e.actions;
        return o;
    },{});

    hits = hits.reduce((o,e) => {
        let hosts = +e.traffic.uniq,
            hits = +e.traffic.raw,
            key = e.slice.landing,
            views = e.views;
        if(!hosts && !hits) return o;
        if(!o[key])o[key] = {
            hosts,
            hits,
            slice : key,
            goals : goals[key] ? goals[key].goals : null,
            sliceData : e.slice.landing,
            views
        };
        return o;
    },{});

    return mergeGoalsAndHitsForSliceTable(goals,hits);
};

const LandNameWithTooltip = ({id,land}) => {
    let landId = (land && land.id) || id,
        [realTitle] =land.title.split('#');
    return <div className={"land-title " + (land ? "land-title__has-land" : "land-title__no-land")}>
        {landId !== 0 ? landId : 'Direct'}
        {
            land && <div className={"small-icon small-icon__info"}></div>
        }
        {
            land && <div className={"popup_content"}>
                <a target={"_blank"} href={land.url_preview}>{realTitle}<div className={"small-icon small-icon__link"}></div></a>
            </div>
        }

    </div>
};

const LandingTable = ({data,sortHandler,showHold}) => {

    const {user} = useContext(userDataContext);

    data = decorator(data).map(e => {
        e.sliceData = <LandNameWithTooltip id={e.sliceData} land={user.landings[e.sliceData]}/>
        return e;
    });
    return <TableView rows={data} sortHandler={sortHandler}  sliceName={'Landing'} hasSliceHover={true} showHold={showHold}/>;
};

export default LandingTable;