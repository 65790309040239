import React, {useState} from 'react';

export const useTableSort  = (initialState = {
    currentFilter : null,
    currentDirection : null
}) => {

    const [sortKey,setSortKey] =  useState(initialState.currentFilter);

    const [direction,setDirection] =  useState(initialState.currentFilter);

    const reset = () => {
        setSortKey(null);
        setDirection(null);
    };

    const handler = (key) => {
        if(sortKey === key){
            direction ? direction === 'forward' ? setDirection('backward') : reset() : setDirection('forward');

        }else if(sortKey !== key){
            setSortKey(key);
            setDirection('forward');

        }
    };

    return {
        sortKey,
        direction,
        handler
    }
};

