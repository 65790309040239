import React from 'react';
import Utils from "../../../../../utils/utils";

const SortTH = ({__html,rowSpan,colSpan,handler,sortKey,sort}) => <th className={"sorted " + sort} rowSpan={rowSpan} colSpan={colSpan} dangerouslySetInnerHTML={{__html}} onClick={() => handler(sortKey)}></th>;

const sortConfig = [
    {

        key: "imps",
        html: "Imps"
    },
    {

        key: "hits",
        html: "Clicks"
    },
    {

        key: "regs",
        html: "Regs"
    },
    {

        key: "FTD",
        html: "FTD"
    },
    {

        key: "total_deps",
        html: "Total<br/>Deps"
    },
    {

        key: "ctr",
        html: "CTR"
    },
    {

        key: "reg_click",
        html: "Reg/Click"
    },
    {

        key: "fd_click",
        html: "FTD/Click"
    },
    {

        key: "fd_reg",
        html: "FTD/Reg"
    },
    {

        key: "FTD_charge",
        html: "FTD"
    },
    {

        key: "total_deps_charge",
        html: "Total<br/>Deps"
    },
    {

        key: "betting_GGR",
        html: "Betting<br/>GGR"
    },
    {

        key: "betting_NGR",
        html: "Betting<br/>NGR"
    },
    {

        key: "casino_GGR",
        html: "Casino<br/>GGR"
    },
    {

        key: "casino_NGR",
        html: "Casino<br/>NGR"
    },
    {

        key: "CPA",
        html: "CPA<br/>(FTD)"
    },
    {

        key: "betting_revshare",
        html: "Betting<br/>Revshare"
    },
    {

        key: "casino_revshare",
        html: "Casino<br/>Revshare"
    },
    {

        key: "total",
        html: "Total"
    }
];

const TableHead = ({sliceName,sortHandler}) => <thead>
<tr>
    <SortTH __html={sliceName} rowSpan={2} sort={sortHandler.sortKey === "slice" ? `sorted_${sortHandler.direction}` : ''} handler={sortHandler.handler} sortKey={"slice"}/>
    <th colSpan={2}>Traffic</th>
    <th colSpan={3}>Quantity</th>
    <th colSpan={4}>CR</th>
    <th colSpan={6}>Amount (€)</th>
    <th colSpan={4}>Earning (€)</th>
</tr>
<tr>
    {
        sortConfig.map(th => <SortTH __html={th.html} key={th.key} sort={sortHandler.sortKey === th.key ? `sorted_${sortHandler.direction}` : ''} handler={sortHandler.handler} sortKey={th.key}/>)
    }
</tr>
</thead>;

export default TableHead;