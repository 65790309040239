import React from 'react';

const SuccessPopup = (props) =>
    <div className={`form-section ${props.closed ? 'closed' : ''} ${props.hidden ? 'hidden' : ''}`}>
        <div className="close-affise-reg-popup"/>
        <div className="form-header">Congratulation!</div>
        <div className="congrats-text">You have been successfully registered.
        {/*<div className="congrats-text">You have been successfully registered.<br/>To activate your account check your*/}
        {/*    email and confirm your registration.*/}
        </div>
    </div>
;


export default SuccessPopup;