import React, {useContext, useEffect, useState} from 'react';
import './css/Stats.scss';
import Table from "./Table";
import FiltersForm from "./FiltersForm";
import Utils from "../../../../utils/utils";
import {AFFISE, userDataContext} from "../../../../utils/Affise";
import SliceSelector from "./SliceSelector";
import {SliceContext,headScrollContext} from "./contexts";
import TableSwitcher from "./sliceView/TableSwitcher";
import {useHeadScroll} from "./useHeadScroll";


const Stats = (props) => {

    let params = Utils.getUrlParameter();

    const {user} = useContext(userDataContext);
    const {sliceList,subsection,thead} = useContext(SliceContext);

    const [showHold,setHold] = useState(false);

    const [filterState,setFilterState] = useState({
        'filter[date_from]' : (params['date_from'] ? (new Date(params['date_from']).getTime()) : null) || Date.now() - 7*24*3600*1000,
        'filter[date_to]' : (params['date_to'] ? (new Date(params['date_to']).getTime()) : null) || Date.now(),
        'filter[currency][]' : 'EUR',
        ...(params.offer ? {offer : params.offer.split(',').map(e => +e)} : {}),
        ...(params.country ? {country : params.country.split(',').map(e => e)} : {})
    });


    const[data,setData] = useState(null);
    const[updating,setUpdating] = useState(false);

    useEffect(() => {
        setUpdating(true);
        let slice = sliceList[subsection].slices;
        AFFISE.loadSliceData({...filterState,slice},subsection).then(data => {
            setData(data);
            setUpdating(false);
        }).catch(e => {
            console.log(e)
            setData({
                goals : [],
                hits : [],
                type : subsection,
                hitsError : 'Server Error!'
            });
            setUpdating(false);
        });
    },[filterState,subsection]);

    return  <>

        <div className={"ui-body-Stats" + (updating ? ` ui-body-Stats_updating` : '')}>
        <SliceSelector/>
        <FiltersForm filtersUpdater={setFilterState} currentFilters={filterState} offers={user.offers}/>
        {
            (data && data.hitsError && data.type === subsection) && <div className={"error-message"}>{data.hitsError}</div>
        }
        <div className={"Stats-table-wrapper"}>
            <div className={"Stats-table-layout"}>
                {
                    (data && data.type === subsection) ? <TableSwitcher subsection={subsection} data={data} sliceTitle={sliceList[subsection].title} setHold={setHold} showHold={showHold}/> : <div>Loading...</div>
                }
            </div>
        </div>
    </div></>
};

export default Stats;