import Utils from "./utils";
import React from 'react';

const normalizeDate = date => date.replace(/(\d\d\d\d)-(\d\d)-(\d\d)/i,'$3-$2-$1');

const AFFISE_TAG_ID = 14;

class Affise {
    constructor() {
        this.checkApiKey();
        this.apiURL = 'https://api-gaming-partners.affise.com';
    }

    checkApiKey(){
        let params = Utils.getUrlParameter();
        if(params.k && params.k.length === 32) Utils.toLS('affiseApiKey',params.k);
        this.apikey = Utils.fromLS('affiseApiKey');
    }

    checkAuth(){
        return  !!this.apikey
    }

    async signUp(data){
        return await fetch(`https://backend.cyberbet.academy/api/affise/sign_up`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data),
        }).then(res => res.json());
    };

    async signIn(data){
        return await fetch(`https://api-gaming-partners.affise.com/3.1/pub-auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: Utils.qsEncode(data),
        }).then(res => res.json());
    }

    async loadUserData(){
        this.checkApiKey();
        let userData = (await this.me()).user;
        if(userData){

            userData.binomLanders = await fetch(`https://backend.cyberbet.academy/api/affise/binomLanders`).then(r => r.json());
            userData.binomLanders = Utils.toObjectByKey(userData.binomLanders.map(e => {
                let link = 'https://land.cyber.bet/' + e.url.replace(/index\..{3,4}$/,'');
                return {
                    url : link,
                    id : e.id,
                    title : e.name,
                    preview : link
                }
            }),'url');

            userData.offers = (await this.offerList()).offers;
            userData.balance = (await this.balance()).balance;
            userData.landings = userData.offers.reduce((o,{landings}) => ({...o,...Utils.toObjectByKey(landings.map(e => {
                e.real_link = e.url_preview.replace(/index\..{3,4}$/,'');
                if(userData.binomLanders[e.real_link]) e.title = userData.binomLanders[e.real_link].title;
                return e;
                }))}),{0 : {
                    id : 0,
                    title : 'Direct CyberBet',
                    realTitle : 'Direct CyberBet',
                    url_preview : 'https://cyber.bet',
                    gameType : 'default',
                    tags:[]
                }});
            userData.creative = this.creativeDecorator((await fetch(`https://backend.cyberbet.academy/api/media/images?action=search&searchBy=tag&tags=${AFFISE_TAG_ID}`).then(r => r.json())));


        }
        return userData;
    }
    async get(url){
        return await fetch(this.apiURL + url,{
            headers: {
                'api-key' : this.apikey
            }
        }).then(r => r.json())
    }
    async getAllPages(url,dataKey) {
        let data = [];
        const getUrl = page => url + `&limit=500&page=${page}`
        const getData = async (page = 1) => {
            let result = await this.get(getUrl(page));
            data = data.concat(result[dataKey]);
            if(result.pagination.next_page) await getData(result.pagination.next_page);
        };
        await getData();
        return data;
    }


    async del(url){
        return await fetch(this.apiURL + url,{
            method: 'DELETE',
            headers: {
                'api-key' : this.apikey
            }
        }).then(r => r.json())
    }
    async postFormData(url,data){
        return await fetch(this.apiURL + url,{
            method: 'POST',
            headers: {
                'api-key' : this.apikey
            },
            body: data,
        }).then(r => r.json())
    }
    async me(){
        return await this.get('/3.1/partner/me')
    }
    async byOffer(){
        return await this.get(`/3.0/stats/getbyprogram?` + this.qs())//filter[date_from]=2020-01-01&filter[date_to]=2022-01-01
    }
    async byGoal(){
        return await this.get(`/3.0/stats/getbygoal?` + this.qs())
    }
    async byRef(){
        return await this.get(`/3.0/stats/getreferralpayments?date_from=${normalizeDate(this.params['filter[date_from]'])}&date_to=${normalizeDate(this.params['filter[date_to]'])}`)
    }
    async offerList(){
        return await this.get('/3.0/offers')
    }
    async balance(){
        return await this.get('/3.0/balance')
    }
    async payments(){
        return (await this.get('/3.1/partner/payment-systems')).systems
    }
    async paymentSystems(){
        return (await this.get('/settings/payment-systems')).payment_systems
    }
    async updatePayments(data,id){
        return (await this.postFormData('/3.1/partner/payment-system/' + id,data))
    }
    async addPayments(data){
        return (await this.postFormData('/3.1/partner/payment-system',data))
    }
    async removePaymentSystem(id){
        return (await this.del(`/3.1/partner/payment-system/${id}/remove`))
    }
    async custom(){
        return (await this.getAllPages(`/3.0/stats/custom?` + this.qs(),'stats'))
    }
    async proxyRequest(){

        this.setParams({
            url : this.qs(),
            api_key : this.apikey
        });
        delete(this.params['filter[date_from]']);
        delete(this.params['filter[date_to]']);
        return (await fetch('https://backend.cyberbet.academy/api/affise/custom?' + this.qs()).then(r => r.json()))
    }
    async confirmEmail(token){
        let result;
        try{
            result = (await fetch(`https://backend.cyberbet.academy/api/affise/confirm?token=${token}`).then(r => r.json()))
        }catch (e) {
            result = null;
        }
        return result;
    }


    async passRecoveryRequest(data){
        return await fetch(`https://backend.cyberbet.academy/api/affise/pass_recovery_request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(data),
        }).then(res => res.json());
    }

    async recoveryPass(data){
        let result;
        try{
            result = await fetch(`https://backend.cyberbet.academy/api/affise/pass_recovery`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(data),
            }).then(res => res.json());
        }catch (e) {
            result = null;
        }
        return result;
    }
    addMultiParam(params,key,getKey){
        if(params[key]){
            Object.assign(params,params[key].reduce((o,e,i) => {
                o[getKey(i)] = e;
                return o;
            },{}));
            delete(params[key]);
        }
    }
    setParams(params){
        this.params = {};
        //console.log(params)
        params['filter[date_from]'] = Utils.getAffiseDate(params['filter[date_from]']);
        params['filter[date_to]'] = Utils.getAffiseDate(params['filter[date_to]']);
        this.addMultiParam(params,'offer',i => `filter[offer][${i}]`);
        this.addMultiParam(params,'country',i => `filter[country][${i}]`);
        this.addMultiParam(params,'slice',i => `slice[${i}]`);
        this.params = params;
    }
    qs(){
        return Utils.qsEncode(this.params)
    }

    async loadStatsData(filters){
        this.setParams({...filters});
        let
            offers = (await  this.byOffer()).stats,
            goals = (await this.byGoal()).stats,
            refs = (await this.byRef()).ref_payments;
        return this.TableDataDecorator({
            offers,
            goals,
            refs
        })
    }

    async loadSliceData(filters,type){
        let hits = [],goals,hitsError;
        this.setParams({...filters});
        switch (type) {
            case "daily" :
                goals = await this.proxyRequest();
                filters.slice = ["day","month","year"];
                this.setParams({...filters});
                try {
                    hits = await this.custom();
                }catch (e) {
                    console.log(e)
                    hitsError = "Hits Fetch error";
                }
                return {goals,hits,type,hitsError};

            case "offers" :
                goals = await this.proxyRequest();
                filters.slice = ["offer"];
                this.setParams({...filters});
                try {
                    hits = await this.custom();
                }catch (e) {
                    console.log(e)
                    hitsError = "Hits Fetch error";
                }
                return {goals,hits,type,hitsError};

            case "countries" :
                goals = await this.proxyRequest();
                filters.slice = ["country"];
                this.setParams({...filters});
                try {
                    hits = await this.custom();
                }catch (e) {
                    console.log(e)
                    hitsError = "Hits Fetch error";
                }
                return {goals,hits,type,hitsError};

            case "landings" :
                goals = await this.proxyRequest();
                filters.slice = ["landing"];
                this.setParams({...filters});
                try {
                    hits = await this.custom();
                }catch (e) {
                    console.log(e)
                    hitsError = "Hits Fetch error";
                }
                return {goals,hits,type,hitsError};

            case "creatives" :
                goals = await this.proxyRequest();
                filters.slice = ["sub5"];
                this.setParams({...filters});
                try {
                    hits = await this.custom();
                }catch (e) {
                    console.log(e)
                    hitsError = "Hits Fetch error";
                }
                return {goals,hits,type,hitsError};

            case "devices" :
                goals = await this.proxyRequest();
                filters.slice = ["device"];
                this.setParams({...filters});
                try {
                    hits = await this.custom();
                }catch (e) {
                    console.log(e)
                    hitsError = "Hits Fetch error";
                }
                return {goals,hits,type,hitsError};

            case "os" :
                goals = await this.proxyRequest();
                filters.slice = ["os"];
                this.setParams({...filters});
                try {
                    hits = await this.custom();
                }catch (e) {
                    console.log(e)
                    hitsError = "Hits Fetch error";
                }
                return {goals,hits,type,hitsError};
        }

        if(type.slice(0,3) === 'sub'){
            goals = await this.proxyRequest();
            filters.slice = [type];
            this.setParams({...filters});
            try {
                hits = await this.custom();
            }catch (e) {
                console.log(e)
                hitsError = "Hits Fetch error";
            }
            return {goals,hits,type,hitsError};
        }

    }

    TableDataDecorator(data){
        let {offers,goals,refs} = data;

        let traffic = Object.values(offers).reduce((o,e) => {
            o.Clicks += (+e.traffic.raw || 0);
            o.Unique_Clicks += (+e.traffic.uniq || 0);
            return o;
        },{Clicks : 0 , Unique_Clicks : 0});

        let count = {
            confirmed : {Regs : 0, FD : 0, RD : 0, Ref : refs.length || 0},
            total : {Regs : 0, FD : 0, RD : 0, Ref : refs.length || 0}
        };

        let revenue = {
            confirmed : {FD : 0, RD : 0, RevShare : 0,Ref : 0},
            total : {FD : 0, RD : 0, RevShare : 0,Ref : refs.length ? Utils.arrSum(refs.map(e => +e.sum_revenue)) : 0}
        };

        Object.values(goals).forEach((e) => {
            let goal = +e.slice.goal || +e.slice.goal_value ;
            switch (goal) {
                case 1 :
                    count.confirmed.Regs += (+e.actions.confirmed.count || 0);
                    break;
                case 2 :
                    count.confirmed.FD += (+e.actions.confirmed.count || 0);
                    count.total.FD += (+e.actions.total.count || 0);
                    revenue.confirmed.FD += (+e.actions.confirmed.revenue || 0);
                    revenue.total.FD += (+e.actions.total.revenue || 0);
                    break;
                case 3 :
                    count.confirmed.RD += (+e.actions.confirmed.count || 0);
                    count.total.RD += (+e.actions.total.count || 0);
                    revenue.confirmed.RD += (+e.actions.confirmed.revenue || 0);
                    revenue.total.RD += (+e.actions.total.revenue || 0);
                    break;
                case 5 :
                    revenue.confirmed.RevShare += (+e.actions.confirmed.revenue || 0);
                    revenue.total.RevShare += (+e.actions.total.revenue || 0);
                    break;
                default : void(0)
            }
        });

        let total = revenue.confirmed.FD + revenue.confirmed.RD + revenue.confirmed.RevShare + revenue.total.Ref;

        return {
            traffic,
            count,
            revenue,
            total
        }


    }

    creativeDecorator(data){

        const gameTypes = ["esports","sport"]
        for(let key in data){
            data[key].realTitle = data[key].title.split('_').slice(0,-1).join(' ');
            data[key].gameType = null;
            data[key].tagList = [];
            Object.values(data[key].tags).forEach(({name,id}) => {
                if(id === AFFISE_TAG_ID) return;
                if(gameTypes.includes(name)){
                    data[key].gameType = name;
                }else{
                    data[key].tagList.push(name);
                }
            });
            data[key].tagList.push(`${data[key].width}x${data[key].height}`);
            data[key].tags = data[key].tagList;
        }
        return data
    }

}


export const AFFISE =  new Affise();

export const authContext = React.createContext();
export const userDataContext = React.createContext({});

const percentage = (a,b) => Math.round((a/b)*10000)/100;

export const prepareSum = (f,c = 'EUR') => (f < 0 ? '-' : '' ) + (c === 'USD' ? '$' : '€') + String((Math.round(Math.abs(f)*100)*10 + 1) / 1000).slice(0,-1);

export const mergeGoalsAndHitsForSliceTable = (goals,hits) => Object.values(Object.assign(goals,hits)).map(row => {
    row.hosts = row.hosts || 0;
    row.hits = row.hits || 0;
    row.imps = row.views || 0;

    row.regs = (row.goals && row.goals[1] && row.goals[1].total.count) || 0;
    row.regs_hold = (row.goals && row.goals[1] && row.goals[1].hold.count) || 0;

    row.FTD = (row.goals && row.goals[2] && row.goals[2].confirmed.count) || 0;
    row.FTD_hold = (row.goals && row.goals[2] && row.goals[2].hold.count) || 0;
    row.FTD_total = (row.goals && row.goals[2] && row.goals[2].total.count) || 0;

    row.RD = (row.goals && row.goals[3] && row.goals[3].confirmed.count) || 0;
    row.RD_hold = (row.goals && row.goals[3] && row.goals[3].hold.count) || 0;

    row.ctr = row.imps &&  row.hits ? percentage(row.hits,row.imps) : 0;

    row.reg_click = row.regs &&  row.hits ? percentage(row.regs,row.hits) : 0;
    row.reg_click_hold = row.regs_hold &&  row.hits ? percentage(row.regs_hold,row.hits) : 0;

    row.fd_click = row.FTD_total &&  row.hits ? percentage(row.FTD_total,row.hits) : 0;

    row.fd_reg = row.FTD_total &&  row.regs ? percentage(row.FTD_total,row.regs) : 0;

    row.total_deps = row.FTD + row.RD;
    row.total_deps_hold = row.FTD_hold + row.RD_hold;

    row.FTD_charge = (row.goals && row.goals[2] && row.goals[2].confirmed.charge) || 0;
    row.FTD_charge_hold = (row.goals && row.goals[2] && row.goals[2].hold.charge) || 0;

    row.RD_charge = (row.goals && row.goals[3] && row.goals[3].confirmed.charge) || 0;
    row.RD_charge_hold = (row.goals && row.goals[3] && row.goals[3].hold.charge) || 0;

    row.total_deps_charge = row.FTD_charge + row.RD_charge;
    row.total_deps_charge_hold = row.FTD_charge_hold + row.RD_charge_hold;

    row.betting_GGR = (row.goals && row.goals[4] && row.goals[4].confirmed.charge) || 0;
    row.betting_GGR_hold = (row.goals && row.goals[4] && row.goals[4].hold.charge) || 0;

    row.betting_NGR = (row.goals && row.goals[5] && row.goals[5].confirmed.charge) || 0;
    row.betting_NGR_hold = (row.goals && row.goals[5] && row.goals[5].hold.charge) || 0;

    row.casino_GGR = (row.goals && row.goals[6] && row.goals[6].confirmed.charge) || 0;
    row.casino_GGR_hold = (row.goals && row.goals[6] && row.goals[6].hold.charge) || 0;

    row.casino_NGR = (row.goals && row.goals[7] && row.goals[7].confirmed.charge) || 0;
    row.casino_NGR_hold = (row.goals && row.goals[7] && row.goals[7].hold.charge) || 0;

    row.CPA = (row.goals && row.goals[2] && row.goals[2].confirmed.revenue) || 0;
    row.CPA_hold = (row.goals && row.goals[2] && row.goals[2].hold.revenue) || 0;

    row.betting_revshare = (row.goals && row.goals[5] && row.goals[5].confirmed.revenue) || 0;
    row.betting_revshare_hold = (row.goals && row.goals[5] && row.goals[5].hold.revenue) || 0;

    row.casino_revshare = (row.goals && row.goals[7] && row.goals[7].confirmed.revenue) || 0;
    row.casino_revshare_hold = (row.goals && row.goals[7] && row.goals[7].hold.revenue) || 0;

    row.total = row.CPA + row.betting_revshare + row.casino_revshare;
    row.total_hold = row.CPA_hold + row.betting_revshare_hold + row.casino_revshare_hold;

    return row;
});