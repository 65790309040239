import React, {useState} from 'react';
import './css/Modal.scss';

const Modal = ({body,title,onClose,bodyComponent}) => {

    return  <div className={"Modal-layout"} data-close={true} onClick={onClose}>
        <div className="Modal-container">
            {
                title && <div className="Modal-title">{title}</div>
            }
            <div className="Modal-body" >
                {
                    bodyComponent
                }
                <div dangerouslySetInnerHTML={{__html : body}}></div>
            </div>
            <div className="close" data-close={true} onClick={onClose}></div>
        </div>
    </div>
};

export default Modal;