import React, {useContext} from 'react';
import {SliceContext} from "./contexts";
import {Link} from "react-router-dom";


const SliceNavLink = ({title,sub,active}) => <Link to={`/ui/stats/${sub}${window.location.search}`} className={"SliceSelector-button " + (active ? "SliceSelector-button__active" : "")}>{title}</Link>

const SliceSelector = (props) => {

    const {subsection,sliceList} = useContext(SliceContext);

    return <div className={"SliceSelector-wrapper"}>
        {
            Object.values(sliceList).map(({title,sub}) => <SliceNavLink sub={sub} title={title} active={subsection === sub} key={sub}/>)
        }
    </div>
};

export default SliceSelector;

//
// slice[]=goal&slice[]=day&filter[offer]=32&filter[date_from]=2021-04-26&filter[date_to]=2021-04-26