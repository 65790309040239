import React from 'react';
import {mergeGoalsAndHitsForSliceTable} from "../../../../../utils/Affise";
import TableView from "./TableView";

const decorator = ({goals,hits}) => {

    goals = goals.reduce((o,e) => {
        let key = e.slice.device || 'N/A';
        if(!o[key])o[key] = {
            slice : key,
            sliceData : key,
            goals : {}
        };
        o[key].goals[e.slice.goal] = e.actions;
        return o;
    },{});

    hits = hits.reduce((o,e) => {
        let hosts = +e.traffic.uniq,
            hits = +e.traffic.raw,
            key = e.slice.device || 'N/A',
            views = e.views;
        if(!hosts && !hits) return o;
        if(!o[key])o[key] = {
            hosts,
            hits,
            slice : key,
            goals : goals[key] ? goals[key].goals : null,
            sliceData : key,
            views
        };
        return o;
    },{});

    return mergeGoalsAndHitsForSliceTable(goals,hits);
};

const DeviceTable = ({data,sortHandler,showHold}) => <TableView rows={decorator(data)} sortHandler={sortHandler}  sliceName={'Device'} showHold={showHold}/>;

export default DeviceTable;