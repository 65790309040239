import React, {useState} from 'react';

const presetsConfig = [
    {
        name : 'Today',
        from : () => (new Date()),
        to : () => (new Date())
    },
    {
        name : 'Yesterday',
        from : () => (new Date(Date.now() - 24*3600*1000)),
        to : () => (new Date(Date.now() - 24*3600*1000))
    },
    {
        name : 'Last 7 days',
        from : () => (new Date(Date.now() - 7*24*3600*1000)),
        to : () => (new Date())
    },
    {
        name : 'This month',
        from : () => {
            let date = new Date;
            date.setDate(1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return date;
        },
        to : () => (new Date())
    },
    {
        name : 'Last month',
        from : () => {
            let date = new Date,
                m = date.getMonth();
            date.setMonth(m-1);
            date.setDate(1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            return date;
        },
        to :  () => {
            let date = new Date;
            date.setDate(1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(-1);
            return date;
        }
    },
    {
        name : 'Custom',
        from : () => (new Date(Date.now() - 7*24*3600*1000)),
        to : () => (new Date())
    }
];

const DatePresetItem = ({active,title,onClick}) => <div className={"Date-presets_item " + (active ? "Date-presets_item__selected" : "")} onClick={onClick}>
    {title}
</div>;

const DatePresets = ({dateInputHandler,preset,setPreset}) => {

    const clickHandler = preset => {
        setPreset(preset);
        dateInputHandler(presetsConfig[preset].from(),'date_from');
        dateInputHandler(presetsConfig[preset].to(),'date_to');
    };

    return <>
        <div className={"Date-presets" }>
            {
                presetsConfig.map((e,i) => <DatePresetItem active={preset === i} title={presetsConfig[i].name} key={i}  onClick={() => clickHandler(i)}/>)
            }
        </div>
    </>
};

export default DatePresets;