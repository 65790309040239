import React, {useContext} from 'react';
import './css/Body.scss';
import {authContext} from "../../../utils/Affise";
import Stats from "../body/Stats/Stats";
import Offers from "../body/Offers/Offers";
import Profile from "../body/Profile/Profile";
import {SliceContext} from "../body/Stats/contexts";
import {Redirect} from "react-router-dom";




const Body = (props) => {

    const sliceList = {
        daily : {
            sub : 'daily',
            title : 'Daily',
            slices : ["goal","day","month","year"],
        },
        offers : {
            sub : 'offers',
            title : 'Offers',
            slices : ["goal","offer"],
        },
        landings : {
            sub : 'landings',
            title : 'Landings',
            slices : ["goal","landing"],
        },
        creatives : {
            sub : 'creatives',
            title : 'Creatives',
            slices : ["goal","sub5"],
        },
        countries : {
            sub : 'countries',
            title : 'Countries',
            slices : ["goal","country"],
        },
        devices : {
            sub : 'devices',
            title : 'Devices',
            slices : ["goal","device"],
        },
        os : {
            sub : 'os',
            title : 'OS',
            slices : ["goal","os"],
        },
        sub1 : {
            sub : 'sub1',
            title : 'Sub1',
            slices : ["goal","sub1"],
        },
        sub2 : {
            sub : 'sub2',
            title : 'Sub2',
            slices : ["goal","sub2"],
        },
        sub3 : {
            sub : 'sub3',
            title : 'Sub3',
            slices : ["goal","sub3"],
        },
        sub6 : {
            sub : 'sub6',
            title : 'Sub6',
            slices : ["goal","sub6"],
        }

    };

    const Sections = {
        'dashboard' :   props.subsections.subsection && sliceList[props.subsections.subsection] ? <Stats/> : <Redirect to={"/dashboard"}/>
    };

    const auth = useContext(authContext);
    return  <div className={"ui-main-Body"}>
        {
            !auth ? <div>Authorization...</div> :
                <SliceContext.Provider value={{
                    ...props.subsections,
                    sliceList
                }}>
                    {
                        Sections[props.section]
                    }
                </SliceContext.Provider>
        }
    </div>
};

export default Body;